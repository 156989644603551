module.exports = [{
      plugin: require('/Users/paul/sites/personal-site/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/paul/sites/personal-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-137251375-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/Users/paul/sites/personal-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
